<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
    </v-skeleton-loader>
    <!-- Start Recent Orange Reports section -->
    <v-card elevation="0">
      <v-card-text>
        <v-row>
          <v-col>
            <h6>
              <span class="font-weight-bold">Recent Orange Reports </span>
              <router-link
                class="blue--text"
                :to="`/customers/${customerId}/orange-reports`"
                >All Orange Reports</router-link
              >
            </h6>
            <v-progress-linear
              v-if="orangeReportsLoading"
              :indeterminate="orangeReportsLoading"
              absolute
              bottom
            ></v-progress-linear>
            <div v-if="!orangeReportsLoading">
              <v-row v-if="sortedOrangeReports.length > 0">
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  v-for="(item, i) in sortedOrangeReports"
                  :key="'o' + i"
                >
                  <div>
                    <p>
                      {{ item.title }}
                      <router-link
                        v-if="item.isApproved"
                        class="orange--text"
                        :to="
                          `/customers/${customerId}/orange-reports/${item.id}/view`
                        "
                        >View Orange Report</router-link
                      >
                      <router-link
                        v-else
                        class="green--text"
                        :to="
                          `/customers/${customerId}/orange-reports/${item.id}/edit`
                        "
                        >{{
                          permissions &&
                          permissions.orangeReport &&
                          permissions.orangeReport.canApprove
                            ? "Edit/Approve "
                            : "Edit "
                        }}
                        Orange Report</router-link
                      >
                    </p>
                    <router-link
                      class="orange--text"
                      :to="
                        `/customers/${customerId}/orange-reports/${item.id}/${
                          item.isApproved ? 'view' : 'edit'
                        }`
                      "
                    >
                      <v-img
                        height="200"
                        width="300"
                        :src="reportPicture(item)"
                      ></v-img>
                    </router-link>
                  </div>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="text-center">
                  <div class="text-subtitle-1 text-center">
                    No Orange Reports
                  </div>
                  <router-link
                    :to="`/customers/${customerId}/orange-reports/new`"
                  >
                    <v-btn class="orange-bg white--text" small
                      >Create Orange Report</v-btn
                    >
                  </router-link>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <!-- End Recent Orange Reports section -->
        <hr style="background-color: #df6526; height: 1px;" />
        <v-row>
          <v-col>
            <!-- Start Recent Invoices -->
            <v-row>
              <v-col>
                <h6>
                  Recent Invoices
                  <router-link
                    class="blue--text"
                    :to="`/customers/${customerId}/invoices/`"
                    >View All</router-link
                  >
                </h6>
              </v-col>
              <v-col>
                <router-link
                  class="blue--text"
                  :to="`/customers/${customerId}/invoices/new`"
                >
                  <v-btn class="orange-bg white--text" small>
                    Upload New Invoice
                  </v-btn>
                </router-link>
              </v-col>
            </v-row>
            <div v-for="(item, i) in communityInfo.invoices" :key="'i' + i">
              <router-link
                class="blue--text"
                :to="`/customers/${customerId}/invoices/${item.id}`"
                >{{ item.name }}</router-link
              >
            </div>

            <!-- End Recent Invoices -->
            <br />
            <!-- Start Recent Proposals -->
            <v-row>
              <v-col>
                <h6>
                  Recent Proposals
                  <router-link
                    class="blue--text"
                    :to="`/customers/${customerId}/proposals/`"
                    >View All</router-link
                  >
                </h6>
              </v-col>
              <v-col>
                <router-link
                  class="blue--text"
                  :to="`/customers/${customerId}/proposals/new`"
                >
                  <v-btn class="orange-bg white--text" small>
                    Upload New Proposal
                  </v-btn>
                </router-link>
              </v-col>
            </v-row>
            <div v-for="(item, i) in communityInfo.proposals" :key="'p' + i">
              <router-link
                class="blue--text"
                :to="`/customers/${customerId}/proposals/${item.id}`"
              >
                {{ item.name }}
              </router-link>
            </div>

            <!-- End Recent Proposals -->
          </v-col>
          <v-col>
            <h6>
              Quarterly Community Enhancement Plan
              <router-link
                class="blue--text"
                :to="`/customers/${customerId}/plans/0/view`"
                >View Plan</router-link
              >
            </h6>
            <v-progress-linear
              :active="ninetyDayPlanLoading"
              :indeterminate="ninetyDayPlanLoading"
              absolute
              bottom
            ></v-progress-linear>
            <div v-if="!ninetyDayPlanLoading">
              <v-progress-linear
                v-if="ninetyDayPlan"
                color="blue"
                :value="ninetyDayPlan.progress"
                height="25"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
              <br />
              <p v-if="ninetyDayPlan" class="text-subtitle-1">
                Quarterly Orange Walk is scheduled for
                {{ formattedQuarterlyOrangeWalkDate }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- </v-container> -->
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import { GET_QUERY, API_CUSTOMERS } from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";

import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";

// import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo
  },
  data: () => ({
    communityInfo: {},
    options: {
      page: 1,
      itemsPerPage: 5
    },
    recentOrangeReports: [],
    sortedOrangeReports: [],
    orangeReportsLoading: false,
    ninetyDayPlan: {},
    ninetyDayPlanLoading: false,
    relatedCommunity: null,
    relatedCommunityItems: [],
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    formattedQuarterlyOrangeWalkDate: null,
    permissions: {}
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {},
  async mounted() {
    // this.loading = true;
    // await this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: "Customers" },
    //   { title: "Comunity Dashboard" }
    // ]);
    // this.customerId = this.$route.params.customerId;
    // await this.getRelatedComunities();
    // await this.getBillingInfo();
    // await this.getInvoices();
    // this.billingInfo.subscription.nextBillingDates = this.formatDate(
    //   this.billingInfo.subscription.nextBillingDates
    // );
    // this.initialValues.plan = this.billingInfo.subscription.plan;
    // this.initialValues.nextBillingDates = this.billingInfo.subscription.nextBillingDates;
    // this.loading = false;
    this.getRecentOrangeReports();
    this.getNinetyDayPlan();
  },
  async created() {
    await this.getComunityInfo();
    permissionsHelper.getPermissions().then(this.getPermissions);
  },
  methods: {
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });

      this.customerId = communityId;

      this.getComunityInfo();
      this.getRecentOrangeReports();
      this.getNinetyDayPlan();
    },
    async getComunityInfo() {
      if (this.loading) return;
      this.loading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive Community details. Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
        });
    },

    async getRecentOrangeReports() {
      // if (this.orangeReportsLoading) return;
      this.orangeReportsLoading = true;

      let reportsUrl = `${API_CUSTOMERS}/${this.customerId}/orangeReports`;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: reportsUrl
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.recentOrangeReports = response.data.$values;

            this.sortedOrangeReports = this.recentOrangeReports
              .slice()
              .sort((a, b) => {
                return new Date(b.serviceDate) - new Date(a.serviceDate);
              })
              .slice(0, 3);
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive recent Orange Reports. Something went wrong!",
              color: "red"
            });
          }

          this.orangeReportsLoading = false;
        });
    },
    async getNinetyDayPlan() {
      if (this.ninetyDayPlanLoading) return;
      this.ninetyDayPlanLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_CUSTOMERS}/${this.customerId}/plans/current`
        })
        .then(response => {
          this.ninetyDayPlan = null;
          if (response.status >= 200 && response.status <= 204) {
            this.ninetyDayPlan = response.data;
            if (this.ninetyDayPlan) {
              this.quarterlyOrangeWalkDate = new Date(
                this.ninetyDayPlan.orangeWalkDate
              )
                .toISOString()
                .substr(0, 10);
              this.formattedQuarterlyOrangeWalkDate = this.formatDate(
                this.quarterlyOrangeWalkDate
              );
            }
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive Quarterly Plan details. Something went wrong!",
              color: "red"
            });
          }

          this.ninetyDayPlanLoading = false;
        });
    },
    // async getRelatedComunities() {
    //   let items = this.getCustomers();
    //   // for (let i = 0; i < items.length; i++) {
    //   //   if (this.customerId == items[i].id) {
    //   //     this.communityInfo = items[i];
    //   //     break;
    //   //   }
    //   // }
    //   items.forEach(element => {
    //     if (
    //       element.contact.name === this.communityInfo.contact.name &&
    //       element.name !== this.communityInfo.name
    //     )
    //       this.relatedCommunityItems.push(element);
    //   });
    // },

    async createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        { title: this.communityInfo.name }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment.utc(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    reportPicture(report) {
      let defaultPicture =
        "https://cdn.vuetifyjs.com/images/parallax/material2.jpg";
      if (!report) return defaultPicture;

      if (report.photos.$values.length > 0) {
        let imagesToShow = report.photos.$values.filter(i => i.showInReport);
        if (imagesToShow && imagesToShow.length > 0)
          return imagesToShow[0].fileUrl;
        return report.photos.$values[0].fileUrl;
      }

      return defaultPicture;
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
.page-specific-background {
  background-image: url("/media/assets/community-info-bg.jpg");
}
</style>
